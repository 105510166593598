@import "colors/inverse.less";
@import "colors/midnightblue.less";
@import "colors/default.less";
@import "colors/primary.less";
@import "colors/orange.less";
@import "colors/alizarin.less";
@import "colors/green.less";
@import "colors/indigo.less";
@import "colors/violet.less";
@import "colors/info.less";
@import "colors/grape.less";



//Mixins
    .header-variant(@variant) {
        @horizontal-background:                 "horizontal-@{variant}-background";
        @horizontal-menu-color:                 "horizontal-@{variant}-menu-color";
        @horizontal-menu-subline:               "horizontal-@{variant}-menu-subline";

        @horizontal-active-bg:                  "horizontal-@{variant}-active-bg";
        @horizontal-active-color:               "horizontal-@{variant}-active-color";
        @horizontal-active-subline:             "horizontal-@{variant}-active-subline";

        @horizontal-hover-bg:                   "horizontal-@{variant}-hover-bg";
        @horizontal-hover-color:                "horizontal-@{variant}-hover-color";
        @horizontal-hover-subline:              "horizontal-@{variant}-hover-subline";

        @horizontal-dropdown-bg:                "horizontal-@{variant}-dropdown-bg";
        @horizontal-dropdown-border:            "horizontal-@{variant}-dropdown-border";
        @horizontal-dropdown-li-border:         "horizontal-@{variant}-dropdown-li-border";
        @horizontal-dropdown-link-color:        "horizontal-@{variant}-dropdown-link-color";
        @horizontal-dropdown-link-hover-color:  "horizontal-@{variant}-dropdown-link-hover-color";
        @horizontal-dropdown-link-hover-bg:     "horizontal-@{variant}-dropdown-link-hover-bg";


        background: @@horizontal-background;
        nav.navbar-collapse {border-color: darken(@@horizontal-background, 7.5%)}
        nav ul.navbar-nav > li {
            > a {
                color: @@horizontal-menu-color;
                .subline {
                    color: @@horizontal-menu-subline;
                }
            }
            &.open>.highlighted, > a:hover {
                background: @@horizontal-hover-bg;
                color: @@horizontal-hover-color;
                .subline {
                    color: @@horizontal-hover-subline;
                }
            }
            &.active > a {
                background: @@horizontal-active-bg;
                color: @@horizontal-active-color;
                .subline {
                    color: @@horizontal-active-subline;
                }
            }
            &.active > a:hover, &.active.open > .highlighted {
                background: @@horizontal-hover-bg;
                color: @@horizontal-hover-color;
                .subline {
                    color: @@horizontal-hover-subline;
                }
            }
        }

        ul.dropdown-menu {
            border-top: 2px solid @@horizontal-dropdown-border !important;
            background: @@horizontal-dropdown-bg !important;
            li {
                border-bottom: 1px solid @@horizontal-dropdown-li-border;
                a {
                    color: @@horizontal-dropdown-link-color;
                    &:hover {color: @@horizontal-dropdown-link-hover-color;}
                }
                a.highlighted, a:hover {
                    background: @@horizontal-dropdown-link-hover-bg !important;
                }
            }
        }

        .mega-menu {
            .yamm-col {
                color: @@horizontal-dropdown-link-color;
                h1, h2, h3, h4, h5, h6, .category {
                    color: @@horizontal-dropdown-link-hover-color;
                }
            }
        }
        .navbar-toggle {
            color: @@horizontal-menu-color;
        }
    }

    .topnav-variant(@variant) {

        @topnav-color: "topnav-@{variant}-color";
        @topnav-bg-color: "topnav-@{variant}-bg-color";
        @topnav-border-color: "topnav-@{variant}-border-color";

        @topnav-brand-color: "topnav-@{variant}-brand-color";
        @topnav-brand-hover-color: "topnav-@{variant}-brand-hover-color";
        @topnav-brand-hover-bg: "topnav-@{variant}-brand-hover-bg";

        @topnav-link-color: "topnav-@{variant}-link-color";
        @topnav-link-hover-color: "topnav-@{variant}-link-hover-color";
        @topnav-link-hover-bg: "topnav-@{variant}-link-hover-bg";
        @topnav-link-active-color: "topnav-@{variant}-link-active-color";
        @topnav-link-active-bg: "topnav-@{variant}-link-active-bg";
        @topnav-link-disabled-color: "topnav-@{variant}-link-disabled-color";
        @topnav-link-disabled-bg: "topnav-@{variant}-link-disabled-bg";

        @topnav-dropdown-bg: "topnav-@{variant}-dropdown-bg";
        @topnav-dropdown-color: "topnav-@{variant}-dropdown-color";
        @topnav-dropdown-link-color: "topnav-@{variant}-dropdown-link-color";
        @topnav-dropdown-link-hover-color: "topnav-@{variant}-dropdown-link-hover-color";
        @topnav-dropdown-link-hover-bg: "topnav-@{variant}-dropdown-link-hover-bg";

        

        background-color: @@topnav-bg-color;
        border-color: @@topnav-border-color;

        .navbar-brand {
            color: @@topnav-brand-color;
            &:hover,
            &:focus {
                color: @@topnav-brand-hover-color;
                background-color: @@topnav-brand-hover-bg;
            }
        }

        .navbar-toggle {
            color: @@topnav-link-color;
        }

        .search {
            .input-icon.right > .form-control,
            .input-icon.left > .form-control {
                color: @@topnav-link-hover-color;
                background: @@topnav-link-hover-bg;
                .placeholder(@@topnav-color);
                &:focus, &.active {
                    color: @@topnav-link-hover-color;
                    background: @@topnav-link-active-bg;
                }
            }
            .input-icon > i {
                margin: 10px;
                color: @@topnav-color;
            }
            
        }

        .navbar-text {
            color: @@topnav-color;
        }

        &.navbar .navbar-collapse {
            border-color: @@topnav-border-color;
            .open .dropdown-menu {
                background-color: @@topnav-dropdown-bg;
                li a {
                    color: @@topnav-dropdown-link-color;
                    &:hover, &:focus {
                        background-color: @@topnav-dropdown-link-hover-bg;
                        color: @@topnav-dropdown-link-hover-color;
                    }
                }
                .divider {
                    border-bottom: 1px solid @@topnav-dropdown-link-hover-bg;
                }
            }
        }

        .navbar-nav {
            > li > a {
                color: @@topnav-link-color;

                &:hover,
                &:focus {
                    color: @@topnav-link-hover-color;
                    background-color: @@topnav-link-hover-bg;
                }
            }

            > .active > a {
                &,
                &:hover,
                &:focus {
                    color: @@topnav-link-active-color;
                    background-color: @@topnav-link-active-bg;
                }
            }

            > .disabled > a {
                &,
                &:hover,
                &:focus {
                    color: @@topnav-link-disabled-color;
                    background-color: @@topnav-link-disabled-bg;
                }
            }

            > .open > a {
                &, &:hover, &:focus {
                    background-color: @@topnav-link-active-bg;
                    color: @@topnav-link-active-color;
                }
            }
        }

            
        .dropdown-menu, .yamm .dropdown-menu {

            box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.1);
            background: @@topnav-dropdown-bg;

            li a {
                color: @@topnav-dropdown-link-color;
                &:hover, &:focus {
                    background-color: @@topnav-dropdown-link-hover-bg;
                    color: @@topnav-dropdown-link-hover-color;
                }
            }

            &.userinfo {
                background: @@topnav-dropdown-bg;
                color: @@topnav-dropdown-color;
                border: none;
                box-shadow: 0 3px 1px rgba(0, 0, 0, 0.1);

                .username a {
                    color: @@topnav-dropdown-link-color;
                }

                a {color: @@topnav-dropdown-link-color;}
                a:hover {
                    background: @@topnav-dropdown-link-hover-bg;
                    color: @@topnav-dropdown-link-hover-color;
                }
            }
            .divider {
                border-bottom: 1px solid @@topnav-dropdown-link-hover-bg;
            }
        }

        .yamm {
            ul li, ul li a {
                color: @@topnav-link-color;
            }

            .yamm-content {
                color: @@topnav-dropdown-color;
                ul li a {
                    color: @@topnav-dropdown-link-color; 
                    &:hover {color: @@topnav-dropdown-link-hover-color;}                
                }
                h1, h2, h3, h4, h5, h6 {&.yamm-category {color: @@topnav-link-active-color;}}
            }

            // if row has .yamm-col-bordered, add a border separator
            .row.yamm-col-bordered>.yamm-col:not(:last-child) {border-right: 1px solid @@topnav-dropdown-link-hover-bg;}
        }

        #navbar-links-toggle {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAABkCAYAAADE6GNbAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QjBGQjlFRjZFMEIxMTFFM0FFMTBFNjU2MDZDMEYwREUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QjBGQjlFRjdFMEIxMTFFM0FFMTBFNjU2MDZDMEYwREUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCMEZCOUVGNEUwQjExMUUzQUUxMEU2NTYwNkMwRjBERSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCMEZCOUVGNUUwQjExMUUzQUUxMEU2NTYwNkMwRjBERSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuKbOvoAAACQSURBVHja7NXBCQAhDABBU601WW1swJeCd+rkb2BYgpGZ5YYJEBAQEBAQEBAQEBAQEBCQ0bTF91URkEcgbfdtKAJyEGT2L6mKgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgHwKWV0SioA8Asndt6EIyEGQ2UWhCAgICAgICAgICAgICAgIyO+nCzAAF7ExSJFDKiwAAAAASUVORK5CYII=);
        }
        #leftmenu-trigger, #rightmenu-trigger {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RjJEREE0NzVFMEIyMTFFM0E2QzREMDM5Q0QyOTI5QzEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RjJEREE0NzZFMEIyMTFFM0E2QzREMDM5Q0QyOTI5QzEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpGMkREQTQ3M0UwQjIxMUUzQTZDNEQwMzlDRDI5MjlDMSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpGMkREQTQ3NEUwQjIxMUUzQTZDNEQwMzlDRDI5MjlDMSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Ppm9f9oAAAD7SURBVHja7NxRCoMwEEVRI+7VNbnauAF/AiHzIud+l8J4ygyF0tZ7P5TT6REAERAgAgJEQIAICBABERAgAgJEQIAICBCldE16n2fgtfdmz2jpbM2PHKwsAXFDRku5K+VzuCFWloC4Ib/4PlIykxtiZQkIEAEBIiBABASIgAgIEAEBIiBABASIgAgIEAEBIiBABASIgAgIEAEBIiBABASIgAgIEAHZuFn/5DDy7wNts2e0dLZZIO3HH9qls1lZbojSbkjyyiufww0Jm8PKckOUekPS11zJTG5I2ExWlhsiIEAEBIiAABEQIAIiIEAEBIiAABEQIAKi714BBgCTKxjRt20bWAAAAABJRU5ErkJggg==);
        }
    }

    .footer-variant(@variant) {

        @footer-color:          "footer-@{variant}-color";
        @footer-background:     "footer-@{variant}-background";
        @footer-link-color:     "footer-@{variant}-link-color";
        @footer-link-hover:     "footer-@{variant}-link-hover";
        @footer-border-color:   "footer-@{variant}-border-color";
        @footer-heading-color:  "footer-@{variant}-heading-color";

        background-color: @@footer-background;
        color: @@footer-color;
        h1, h2, h3, h4, h5, h6 {color: @@footer-heading-color}

        a {
            color: @@footer-link-color;
            &:hover {color: @@footer-link-hover;}
        }

        ul.recent-news, ul.recent-posts {
            li {border-bottom: 1px solid @@footer-border-color; &:last-child {border-bottom: 0}}

        }
        .footer-bottom {
            hr {border-top: 1px solid @@footer-border-color;}
        }
    }


//Headerbar
    #headernav.navbar-inverse {
    	.header-variant(~"inverse");
    }

    #headernav.navbar-midnightblue {
        .header-variant(~"midnightblue");
    }

    #headernav.navbar-default {
        .header-variant(~"default");
    }

    #headernav.navbar-primary {
        .header-variant(~"primary");
    }

    #headernav.navbar-orange {
        .header-variant(~"orange");
    }

    #headernav.navbar-alizarin {
        .header-variant(~"alizarin");
    }

    #headernav.navbar-green {
        .header-variant(~"green");
    }

    #headernav.navbar-indigo {
        .header-variant(~"indigo");
    }

    #headernav.navbar-violet {
        .header-variant(~"violet");
    }

    #headernav.navbar-grape {
        .header-variant(~"grape");
    }

    #headernav.navbar-info {
        .header-variant(~"info");
    }


//Topnav
    #topnav.navbar-inverse {
        .topnav-variant(~"inverse");
    }

    #topnav.navbar-midnightblue {
        .topnav-variant(~"midnightblue");
    }

    #topnav.navbar-primary {
        .topnav-variant(~"primary");
    }

    #topnav.navbar-orange {
        .topnav-variant(~"orange");
    }

    #topnav.navbar-alizarin {
        .topnav-variant(~"alizarin");
    }

    #topnav.navbar-green {
        .topnav-variant(~"green");
    }

    #topnav.navbar-indigo {
        .topnav-variant(~"indigo");
    }

    #topnav.navbar-violet {
        .topnav-variant(~"violet");
    }

    #topnav.navbar-grape {
        .topnav-variant(~"grape");
    }

    #topnav.navbar-info {
        .topnav-variant(~"info");
    }

    //Default
    #topnav.navbar-default {

        //Toolbar Icon BG Default
        .toolbar-icon-bg.open > a span.icon-bg {background-color: rgba(0,0,0,0.1) !important; &:hover {background-color: rgba(0,0,0,0.1) !important;}}
        .toolbar-icon-bg.open > a {background: none !important; &:hover {background: none !important;}}
        .toolbar.pull-right li.toolbar-icon-bg > a span.icon-bg {background-color: rgba(0,0,0,0.05); border-radius: @border-radius-base;}
        .toolbar.pull-right li.toolbar-icon-bg:hover {span.icon-bg {background-color: rgba(0,0,0,0.1);}}

        .dropdown-menu {
            background: @navbar-default-link-active-bg;
            color: @gray-dark;
            border: 1px solid @border-darker;
            border-top: 0;
            &.userinfo {
                background: @navbar-default-link-active-bg;
                color: @gray;
                border: 1px solid @border-darker;
                border-top: none;
                a {color: @gray-dark;}
                a:hover {
                    background: @border-lighter;
                    color: @gray-darker;
                }
            }
            .divider {
                border-bottom: 1px solid @border-lighter;
            }
        }

        .navbar-nav .open .dropdown-menu > li > a {
            color: @gray-dark;
            &:hover {
                color: @gray-darker;
                background: @dropdown-link-hover-bg;
            }
        }   



        .yamm {

            //.yamm-content h6 {border-bottom: 1px solid rgba(0, 0, 0, 0.06);}
            .yamm-content h6 .yamm-category {
                color: @gray;
            }
            .yamm-content ul li, 
            .yamm-content ul li a {
                color: @gray-dark;
            }

            .dropdown-menu {
                border: 1px solid @border-darker;
                border-top: none;
                ul li, ul li a, li, li a {
                    color: @gray-dark;
                }
                ul li:hover {
                    background: @dropdown-link-hover-bg;
                    a {color: @gray-darker;}
                }
                li a:hover {
                    background: @dropdown-link-hover-bg;
                    color: @gray-darker;
                }
            }

            // if row has .yamm-col-bordered, add a border separator
            .row.yamm-col-bordered>.yamm-col:not(:last-child) {border-right: 1px solid @border-lighter;}
        }

        #navbar-links-toggle {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAABkCAYAAADE6GNbAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QkQ2NEZDRENFMEIxMTFFMzlGNEZGMjJFQzU2OTAyMDMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QkQ2NEZDRERFMEIxMTFFMzlGNEZGMjJFQzU2OTAyMDMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCRDY0RkNEQUUwQjExMUUzOUY0RkYyMkVDNTY5MDIwMyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCRDY0RkNEQkUwQjExMUUzOUY0RkYyMkVDNTY5MDIwMyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjkAYMwAAAClSURBVHja7NpLEcAwCAXA4imaKiqa6inRkM8hDYsCdt4wcCBaa88NFSAgICAgICAgICAgICAgeyARsaWR5T42Qd5FRz0Fck0iIKdCRmelSgQkEWR2l1SJuLVAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH4Gue3z4Vt0FJ8PIEkgo7NSJAKSCDK7S4pE3FogICAgICAgICAgICAgIMdXF2AAwhJzSF8qHmUAAAAASUVORK5CYII=);
        }

        #leftmenu-trigger, #rightmenu-trigger {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MDBBNkJFOTJFMEIzMTFFMzk0MzlBQjYyNzBGNDNDMUQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MDBBNkJFOTNFMEIzMTFFMzk0MzlBQjYyNzBGNDNDMUQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowMEE2QkU5MEUwQjMxMUUzOTQzOUFCNjI3MEY0M0MxRCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowMEE2QkU5MUUwQjMxMUUzOTQzOUFCNjI3MEY0M0MxRCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PmVhaN0AAAEQSURBVHja7NzBDYMwDEBRshMzMRQzsVOYwcFWjPT+narh4UQc2jHnPNSnAQSIgAARECACAkRAgLgLQAQEiIAAERAgAgLkwweMkfJFdj8YXdaRBXIFLrl/BhJaWxcQE9JsQoAAAZJ1hpTsvUkg29dhQmxZQIA4Q+LvIw1BltZkQkyISh8MIEAEBIiAABEQIAICxF0AIiBABASIgAARECACIiBABASIgAARECACIiBABASIgAARECAComwQv1PPXUcWyBO45PwZSGht/snBhAABAqT0DCnZe5NAtq/DhNiygABxhsTfRxqCLK3JhJgQlT4YQIAICBABASIgQAQEiLsARECACAgQAQEiIEDUpVeAAQBZcbtIRXCCjAAAAABJRU5ErkJggg==);
        }
    }


//Footer

    footer.footer-midnightblue {
        .footer-variant(~"midnightblue");
    }

    footer.footer-inverse {
        .footer-variant(~"inverse");
    }

    footer.footer-default {
        .footer-variant(~"default");
    }

    footer.footer-primary {
        .footer-variant(~"primary");
    }

    footer.footer-orange {
        .footer-variant(~"orange");
    }

    footer.footer-alizarin {
        .footer-variant(~"alizarin");
    }

    footer.footer-green {
        .footer-variant(~"green");
    }

    footer.footer-indigo {
        .footer-variant(~"indigo");
    }

    footer.footer-violet {
        .footer-variant(~"violet");
    }

    footer.footer-grape {
        .footer-variant(~"grape");
    }

    footer.footer-info {
        .footer-variant(~"info");
    }