/* jQueryUI Border Radius */
.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl 		{border-top-left-radius: 	   @border-radius-small;}
.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr 	{border-top-right-radius: 	   @border-radius-small;}
.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl  	{border-bottom-left-radius:    @border-radius-small;}
.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br 	{border-bottom-right-radius:   @border-radius-small;}

.ui-slider {

	&.primary .ui-widget-header, &.primary .ui-slider-handle:hover, &.primary .ui-slider-handle:active {
		background: @brand-primary !important;
		background-color: @brand-primary !important;
	}
	&.success .ui-widget-header, &.success .ui-slider-handle:hover, &.success .ui-slider-handle:active {
		background: @brand-success !important;
		background-color: @brand-success !important;
	}
	&.info .ui-widget-header, &.info .ui-slider-handle:hover, &.info .ui-slider-handle:active {
		background: @brand-info !important;
		background-color: @brand-info !important;
	}
	&.warning .ui-widget-header, &.warning .ui-slider-handle:hover, &.warning .ui-slider-handle:active {
		background: @brand-warning !important;
		background-color: @brand-warning !important;
	}
	&.danger .ui-widget-header, &.danger .ui-slider-handle:hover, &.danger .ui-slider-handle:active {
		background: @brand-danger !important;
		background-color: @brand-danger !important;
	}
	&.midnightblue .ui-widget-header, &.midnightblue .ui-slider-handle:hover, &.midnightblue .ui-slider-handle:active {
		background: @brand-midnightblue !important;
		background-color: @brand-midnightblue !important;
	}

	&.ui-widget-content {
		background: @border-lighter !important;
		box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.04);
		border: none !important;
		border-radius: 3px !important;
		margin-top: 8px !important;
	}

	.ui-widget-header {
		background: @brand-inverse !important;
		box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.04);
		border: none !important;
		border-radius: 3px !important;

	}
	&.ui-slider-horizontal .ui-slider-handle {
		top: -6px !important;
	}
	&.ui-slider-vertical .ui-slider-handle {
		left: -6px !important;
	}
	.ui-slider-handle {
		border-radius:50%;
		color: @border-darker !important;
		background: #bdc3c7 !important;
		border: 5px solid @brand-default !important;
		box-shadow: 0 0 0 1px #bdc3c7, 0 2px 0 0 rgba(0, 0, 0, 0.04);
		width: 8px !important;
		height: 8px !important;

		&:hover, &:active {
			color: @brand-inverse !important;
			background-color: @brand-inverse !important;
			border-color: #fff !important;
		}
	}
}


/* Sparklines Tooltip */
.jqstooltip {
	width: auto !important;
	height: auto !important;
	border: none !important;
}

/*Textarea*/

textarea {
	border-radius: @border-radius-base;
	border: 1px solid @border-darker;
	border-top: 1px solid darken(@border-darker, 2%);
	padding: 6px 10px;
	background: @tone-brighter;
	-webkit-box-shadow: inset 0 1px 0px rgba(0, 0, 0, 0.01) !important;
	box-shadow: inset 0 1px 0px rgba(0, 0, 0, 0.01) !important;
	&:focus {
      border: 1px solid @brand-primary;
      background: #fff;
      box-shadow: 0 0px 1px 0px lighten(@brand-primary, 40%) !important;
    }
}

/* Textarea Autosize */
textarea.autosize {
  vertical-align: top; 
  transition: height 0.2s;
  -webkit-transition: height 0.2s; 
  -moz-transition: height 0.2s; 
}

/* Stepy Wizard */
.stepy-header {
	display: table;
	table-layout: fixed;
	width: 100%;
	list-style: none;
	padding-left:0;
	padding-bottom: 10px;
	border-bottom: 1px solid @border-lighter;
	margin-bottom: 20px;
	li {
		display: table-cell;
		cursor: pointer;
		.h5;
		div {
			.h2;
			font-family: @font-family-base;
			font-family: 300;
		}
		&:not(.stepy-active) {
			opacity: 0.3;
			&:hover {opacity: 0.7;}
		}
	}
	&.panel-footer {
		margin: 20px 0 -21px 0px;
		padding: 15px 20px;
		border-top: 1px solid @panel-inner-border !important;
	}
}

.stepy-navigator.panel-footer {
	&:extend(.clearfix all);
	.btn {margin-left: 10px;}
}

/* WYSIHTML5 */
.wysihtml5-toolbar {
	.list-inline;
	> li {
		padding: 0 10px 10px 0;
		.dropdown-menu {margin-top:-10px;left: 0;}
	}
}

.wysihtml5-sandbox {
	border: 1px solid @input-border !important; 
	padding: 5px 9px !important;}

/* Nicescroll */
.nicescroll-rails {
	width: 6px !important;
	margin: 1px 0 !important;
	border-top-left-radius: @border-radius-small !important;
	border-top-right-radius: @border-radius-small !important;
	border-bottom-right-radius: @border-radius-small !important;
	border-bottom-left-radius: @border-radius-small !important;
	> div {
		.opacity(0.2) !important;
		border:none !important;
		width: 6px !important;
		border-radius: @border-radius-base !important;
	}
}

/* FullCalendar*/

.fc table {
	font-size: @font-size-mini !important;
}

.panel {
	.fc-header {
		margin-bottom: 20px;
		h2 {
			.h3();
			font-weight: 300;
			// text-transform: uppercase;
			margin: 0;
		}
		@media (max-width: @screen-xs) {
			h2 {.h5(); font-weight: 400;}
		}
		.fc-button {
			.btn;
			.btn-default;
			.btn-sm;
			border-radius:0;
			&:active,&.active,&.fc-state-active {.active;}
		}
		.fc-corner-left  {.border-left-radius(@border-radius-base)}
		.fc-corner-right {.border-right-radius(@border-radius-base)}
	}
	.fc-content table.fc-agenda-allday {border-radius: 0}
	.fc-content table {
		.fc-widget-content {border: 1px solid @border-lighter;}
		border-radius: @border-radius-base;
		&:extend(.table-bordered all);
		thead th {border-top: 0 !important; font-size: @font-size-small; font-weight: 400; background: @tone-brighter;}
		tbody > tr > td {background-color: @tone-brighter; border: 1px solid @border-lighter; &:first-child{border-left: 0}}
		tbody > tr > th:first-child {border-left: 0; border-bottom: 0;}
		tbody > tr > th:last-child {border-right: 0; border-bottom: 0;}

		.fc-week .fc-day .fc-day-number {color: lighten(@gray, 5%);}
		.fc-day.fc-other-month.fc-future {background: @tone-bright; .fc-day-number{color: lighten(@gray, 5%) !important;}}
	}
	.fc-event {
		font-size: @font-size-mini;
		line-height: 1em;
		padding: 0;
		border-radius: @border-radius-small;
		box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
		.fc-event-inner {
			padding: 3px;
			background: rgba(255, 255, 255, 0.25);
			margin-left: 2px;
			margin-bottom: 2px;
			.fc-event-time {
				.opacity(0.6);
				font-weight: 400;
				text-transform: uppercase;
			}
			.fc-event-title {
				padding: 0 2px;
			}
		}
	}
	#external-events {
		.external-event.label {
			letter-spacing: normal;
			text-transform: none;
			box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1), inset 2px -2px 0 0 rgba(0, 0, 0, 0.1);
			font-size: @font-size-mini;
			padding: 4px 6px;
			line-height: 1em;
			margin-bottom: 2px;
		}
	}
}



.external-event {cursor: move;}


/* Toggle */
.toggle-primary {.toggle-variant(@brand-primary;)}
.toggle-success {.toggle-variant(@brand-success;)}
.toggle-warning {.toggle-variant(@brand-warning;)}
.toggle-danger  {.toggle-variant(@brand-danger;)}
.toggle-info  	{.toggle-variant(@brand-info;)}
.toggle-inverse {.toggle-variant(@brand-inverse;)}

.toggle-variant(@color) {
	.toggle-slide .toggle-on,.toggle-slide .toggle-on.active {background-color: @color;}
	.toggle-slide.active .toggle-blob {border: 1px solid @color;}
}

.toggle-slide {
	border-radius: @border-radius-small;
	.toggle-blob {
		border-radius: @border-radius-small;
	}
}

/* Shuffle.js */


.gallery {
	&:extend(.clearfix all);
	padding-left: 0;
	cursor: pointer;

	.item {
		position: relative;
		//overflow: hidden;
		margin-bottom: 20px;
		border-radius: @border-radius-base;
		-webkit-mask-image: -webkit-radial-gradient(circle,white,black);
		
		a {display: block;}

		h3 {
			.rgba(black,0.7);
			position: absolute;
			bottom: -38px;
			left: 0;
			right: 0;
			padding-left: 10px !important;

			padding: 5px;
			//font-size: @font-size-base;
			color: #fff;

			margin: 0;
			display: block;
			.transition(~"all 250ms ease");
		}

		&:hover h3 {bottom: 0; padding-left: 10px;}
	}
}

/* Flot */
.legend .legendLabel {padding-left: 4px;}

#flotTip {
	border: none !important;
	font-size: @font-size-small !important;
	line-height: 1 !important;
	.tooltip-inner() !important;
}

//Typeahead

.twitter-typeahead {width: 100%; position: relative;vertical-align: text-top;}

.tt-hint {
  .form-control();
}

.tt-dropdown-menu {
	&:extend(.dropdown-menu);
}

.tt-suggestion {
	> p {
		margin: 0 !important;
		color: @dropdown-link-color !important;
		&:extend(.dropdown-menu > li > a);
		&:hover, &:focus {
			&:extend(.dropdown-menu > .active > a);
		}
	}
	&.tt-cursor > p {
		&:extend(.dropdown-menu > .active > a);
	}
}


//EasyPieChart
.easypiechart{
	position: relative;
	display: block;
	width: 90px;
	height: 90px;
	line-height: 90px;
	margin: 0 auto;
	text-align: center;
	.percent {
		position: absolute;
		width: 90px;
		line-height: 90px;
		font-size: @font-size-h3;
		font-weight: 300;
		color: #808080;
		&:after{
			content: '%';
		}
	}
	& + label {
		text-align: center;
		display: block;
		margin-top: 5px;
		margin-bottom: 0;
	}
}

//X-Editable
.popover-content .form-group {margin: 0 !important; padding: 10px 0 0 !important; &:first-child{margin: 0 !important; padding: 0 !important}}

.editable-container {
	.form-group {margin-left: -10px;margin-right: -10px; padding-bottom: 7px;}

}

//Tokenfield



.tokenfield {
	height: auto !important;;
	min-height: 34px !important;;
	padding-bottom: 0px !important;;
	padding: 6px 6px 5px !important;;
	.twitter-typeahead {width: auto !important;}
	.tt-hint {
	  font-size: @font-size-base !important;
	  line-height: 1 !important;
	  border: 0 !important;
	  padding: 0 !important;
	  height: 19px !important;
	  .box-shadow(~"none") !important;
	}
	.token {margin-bottom: 0 !important; border: 1px solid transparent !important}
}
.token-input {margin-bottom: 0 !important;}

@-webkit-keyframes 'blink' {
  0% {
    border-color: @border-lighter !important;
  }
}
@-moz-keyframes 'blink' {
  0% {
    border-color: @border-lighter !important;
  }
}
@keyframes 'blink' {
  0% {
    border-color: @border-lighter !important;
  }
}
.tokenfield .token {
  border: 1px solid @border-lighter !important;
  background-color: @border-lighter !important;
}

.tokenfield .token.invalid.active {
  background: @border-lighter !important;
  border: 1px solid @border-lighter !important;
}






// Jasny File Input

.btn-file {
    position: relative;
    vertical-align: middle;

    .border-right-radius(@border-radius-base) !important;
    & > input {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        opacity: 0;
        filter: alpha(opacity=0);
        font-size: 23px;
        height: 100%;
        width: 100%;
        direction: ltr;
        cursor: pointer;
    }
}
.fileinput {
    margin-bottom: 9px;
    display: inline-block;
    .form-control {
        padding-top: 7px;
        padding-bottom: 5px;
        display: inline-block;
        margin-bottom: 0px;
        vertical-align: middle;
        cursor: text;
    }
    .thumbnail {
        overflow: hidden;
        display: inline-block;
        margin-bottom: 5px;
        vertical-align: middle;
        text-align: center;
        & > img {
            max-height: 100%;
        }
    }
    .btn {
        vertical-align: middle;
    }
}
.fileinput-exists .fileinput-new,.fileinput-new .fileinput-exists {
    display: none;
}
.fileinput-inline {
    .fileinput-controls {
        display: inline;
    }
}
.fileinput-filename {
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
}
.form-control {
    .fileinput-filename {
        vertical-align: bottom;
    }
}
.fileinput.input-group {
    display: table;
    & > * {
        position: relative;
        z-index: 2;
    }
    & > .btn-file {
        z-index: 1;
    }
}
// .fileinput-new {
// 	&.input-group .btn-file,& .input-group .btn-file {
// 	    border-radius: 0 4px 4px 0;
// 	}
// 	&.input-group .btn-file.btn-xs,& .input-group .btn-file.btn-xs,&.input-group .btn-file.btn-sm,& .input-group .btn-file.btn-sm {
// 	    border-radius: 0 3px 3px 0;
// 	}
// 	&.input-group .btn-file.btn-lg,& .input-group .btn-file.btn-lg {
// 	    border-radius: 0 6px 6px 0;
// 	}
// }

.form-group {
	&.has-warning {
		.form-control {.placeholder(lighten(@brand-warning, 20%)); background-color: lighten(@brand-warning, 46%); &:focus {background-color: #fff;}}
		.input-group-addon {background-color: lighten(@brand-warning, 35%) !important;}
	    .fileinput {
	        .fileinput-preview {color: #8a6d3b;}
	        .thumbnail {border-color: #faebcc;}
	    }
	}
	&.has-error {
		.form-control {.placeholder(lighten(@brand-danger, 20%)); background-color: lighten(@brand-danger, 41%); &:focus {background-color: #fff;}}
		.input-group-addon {background-color: lighten(@brand-danger, 35%) !important;}
	    .fileinput {
	        .fileinput-preview {color: #a94442;}
	        .thumbnail {border-color: #ebccd1;}
	    }
	}
	&.has-success {
		.form-control {.placeholder(lighten(@brand-success, 20%)); background-color: lighten(@brand-success, 48%); &:focus {background-color: #fff;}}
		.input-group-addon {background-color: lighten(@brand-success, 35%) !important;}
	    .fileinput {
	        .fileinput-preview {color: #3c763d;}
	        .thumbnail {border-color: #d6e9c6;}
	    }
	}
}
.input-group-addon:not(:first-child) {
    border-left: 0;
}


// Markdown Editor

.wmd-preview {margin-top: 25px;}
.wmd-panel .btn-toolbar {padding: 0 0 10px !important;}

.wmd-button-bar {
	  .btn-group + .btn-group, .btn-group:first-child:not(:last-child) {
	    margin-right: 3px;
	  }
}


// iCheck

label.icheck {
	padding: 7px 0 !important;
	line-height: @line-height-base !important;
	> div {
		//width: 20px !important;
		//margin-right: 5px !important;

		// [class*="iradio"], [class*="icheck"] {

		// }
	}
	input {display: none;}
	> label {padding: 0 !important}

	[class*="icheckbox_minimal-"], [class*="iradio_minimal-"] {
		float: left;
		margin: 2px 5px 0 0 !important;
	}

	
}

label.radio.icheck,
label.checkbox.icheck {
	cursor: pointer;
}




[class*="icheckbox_minimal-"], [class*="iradio_minimal-"] {
	float: left;
	margin: 0 !important;
}


//.radio label, .checkbox label {padding-left: 0 !important;}

.icheck {
	&.checkbox, &.checkbox-inline,
	&.radio,    &.radio-inline,
	label {
		padding-left: 0;
		line-height: @line-height-base;
		// > label {padding-top: 3px;}
		> div, > label > div {margin-right: 5px !important;}
	}

	&.checkbox-inline, &.radio-inline, &.checkbox, &.radio {
		//padding-top: 10px;
		//th &, td & {padding-top: 0 !important;}

		.form-inline &, .form-horizontal & {padding-top: 10px;}
	}

	// blue

	.icheckbox_minimal-blue.checked.hover {
		background-position: -200px 0;
	}
	.iradio_minimal-blue.checked.hover {
		background-position: -220px 0;
	}
	// green
	.icheckbox_minimal-green.checked.hover {
		background-position: -200px 0;
	}
	.iradio_minimal-green.checked.hover {
		background-position: -220px 0;
	}
	// aero
	.icheckbox_minimal-aero.checked.hover {
		background-position: -200px 0;
	}
	.iradio_minimal-aero.checked.hover {
		background-position: -220px 0;
	}
	// grey
	.icheckbox_minimal-grey.checked.hover {
		background-position: -200px 0;
	}
	.iradio_minimal-grey.checked.hover {
		background-position: -220px 0;
	}
	// orange
	.icheckbox_minimal-orange.checked.hover {
		background-position: -200px 0;
	}
	.iradio_minimal-orange.checked.hover {
		background-position: -220px 0;
	}
	// pink
	.icheckbox_minimal-pink.checked.hover {
		background-position: -200px 0;
	}
	.iradio_minimal-pink.checked.hover {
		background-position: -220px 0;
	}
	// purple
	.icheckbox_minimal-purple.checked.hover {
		background-position: -200px 0;
	}
	.iradio_minimal-purple.checked.hover {
		background-position: -220px 0;
	}
	// red
	.icheckbox_minimal-red.checked.hover {
		background-position: -200px 0;
	}
	.iradio_minimal-red.checked.hover {
		background-position: -220px 0;
	}
	// yellow
	.icheckbox_minimal-yellow.checked.hover {
		background-position: -200px 0;
	}
	.iradio_minimal-yellow.checked.hover {
		background-position: -220px 0;
	}
}


//Clockface
.clockface .outer.active,
.clockface .outer.active:hover {
	background: @brand-success;
	color: #fff;
}

.clockface .inner.active,
.clockface .inner.active:hover {
	background: @brand-primary;
	color: #fff;
}

.clockface .outer,
.clockface .inner {
	border-radius: @border-radius-base;
}




//bootstrap token
.tokenfield .token {border-radius: @border-radius-small;}

// Multi Select

.ms-container .ms-selectable, .ms-container .ms-selection{
  background: #fff;
  color: @gray-dark !important;
}

.ms-container .ms-list{
  border: 1px solid @border-darker !important;
  border-top: 1px solid @border-darker !important;
  -webkit-box-shadow: inset 0 1px 0px rgba(0, 0, 0, 0.01) !important;
  box-shadow: inset 0 1px 0px rgba(0, 0, 0, 0.01) !important;
  position: static !important;
}



.ms-container .ms-list.ms-focus{
  border-color: @brand-primary !important;
}


.ms-container .ms-optgroup-label{
  color: @border-darker !important;
}

.ms-container .ms-selectable li.ms-elem-selectable,
.ms-container .ms-selection li.ms-elem-selection{
  border-bottom: 1px @border-lighter solid !important;
  color: @gray-dark !important;
}

.ms-container .ms-selectable li.ms-hover,
.ms-container .ms-selection li.ms-hover{
  color: @gray-dark !important;
  background-color: @border-lighter !important;
}

.ms-container .ms-selectable li.disabled,
.ms-container .ms-selection li.disabled{
  background-color: @border-lighter !important;
  color: @gray-light !important;
}

//select2
.select2-drop {
	border: 1px solid @brand-primary !important;
	//box-shadow: 0 0px 1px 0px #e1f0fa !important;
	border-radius: @border-radius-base;
	&.select2-drop-active {
		border-top-width: 0 !important;
		.border-top-radius(0);
		&.select2-drop-above {
			border-top-width: 1px !important;
			border-bottom-width: 0 !important;
			.border-top-radius(@border-radius-base);
			.border-bottom-radius(0);
		}
	}
}

.select2-dropdown-open {
	.border-top-radius(@border-radius-base);
	&.select2-drop-above .select2-choices{
		.border-top-radius(0);
		.border-bottom-radius(@border-radius-base);
	}

	.select2-choice, .select2-choices {
		border: 1px solid @brand-primary !important;
		background-color: #fff;
		box-shadow: 0 0px 1px 0px #e1f0fa !important;
		.box-shadow(~"none") !important;
	}
}

.select2-container-multi .select2-choices {
	padding-left: 4px;
	.select2-search-choice {
		padding: 3px 5px 3px 18px !important;
		border: 1px solid @border-lighter !important;
		border-radius: @border-radius-base !important;
		background: @border-lighter !important;
		color: @gray-dark !important;
		margin: 6px 0 3px 5px !important;
	}
}

.select2-container-multi.select2-dropdown-open .select2-choices {
	background: #fff !important;
}



//icheck

.icheckbox_minimal-blue, .iradio_minimal-blue {
	// height: 16px !important;
	// width: 16px !important;
}

// Tabdrop
.nav-tabs, .nav-pills {position: relative;}
.tab-container .nav-tabs li.tabdrop +li > a{margin-left: 0px !important;}


// uipnotify

.ui-pnotify {
	.alert-warning {.ui-pnotify-variant(@brand-warning)}
	.alert-success {.ui-pnotify-variant(@brand-success)}
	.alert-danger {.ui-pnotify-variant(@brand-danger)}
	.alert-info {.ui-pnotify-variant(@brand-info)}
}

.ui-pnotify-variant(@background) {
  background-color: @background;
  border-color: @background;
  color: #fff;

  hr {
    border-top-color: rgba(255, 255, 255, 0.1);
  }
  .alert-link {
    color: darken(#fff, 10%);
  }

  h1, h2, h3, h4, h5, h6, 
  .h1, .h2, .h3, .h4, .h5, .h6 {
    color: #fff;
    small {
      color: #fff; 
    }
  }
}

.ui-pnotify-closer, .ui-pnotify-sticker {
	padding: 2px 8px;
	margin: -16px 0 0 0 !important;
	background: rgba(0, 0, 0, 0.1);
	font-size: 10px;
	color: rgba(255, 255, 255, 0.6);
	&:hover, &:active, &:focus {background: rgba(0, 0, 0, 0.2);}
}

.ui-pnotify-closer {border-bottom-right-radius: @border-radius-base;}

.ui-pnotify-sticker {border-bottom-left-radius: @border-radius-base;}

/*Slimscroll*/
// .panel-body > .slimScrollDiv {
// 	left: 10px;

// 	> .panel-scroll, 
// 	> .panel-scroll-rail,
// 	> .panel-scroll-visible {
// 		position: relative;
// 		left: -10px;
// 	}
// }






// switchery


// Switchery normal
.switchery {
  border: 0px solid transparent !important;
  box-shadow: inset 0 1px 1px 1px rgba(0, 0, 0, 0.1) !important;
  border-radius: 34px !important;
  height: 34px !important;
  width: 68px !important;
  > small {
  	border-radius: 100% !important;
  	border: 0px solid transparent !important;
  	box-shadow: 0 0px 0px 1px rgba(0, 0, 0, 0.22) !important;
  	height: 34px !important;
  	width: 34px !important;
  }
}

.switchery-xs + .switchery {
  	height: 20px !important;
  	width: 40px !important;
  	> small {
  		height: 20px !important;
  		width: 20px !important;
  	}
}

.switchery-sm + .switchery {
  	height: 30px !important;
  	width: 60px !important;
  	> small {
  		height: 30px !important;
  		width: 30px !important;
  	}
}


// jquery nestable


.dd-handle, .dd3-content {
	height: 34px !important;
	margin: 3px 0 !important;
	padding-top: 6px !important;
	padding-bottom: 6px !important;
	color: @gray-dark !important;
	border: 1px solid @border-darker !important;
	background: @tone-brighter !important;
	//border-radius: @border-radius-base !important;
	
}

.dd3-handle.success-bg .dd3-content, .dd-handle.success-bg {
		background-color: lighten(@brand-success, 42%) !important;
		border: 1px solid @brand-success !important;
	}

.dd3-handle {
	margin: 0 !important;
	width: 22px !important;
	border: 1px solid @border-darker !important;
	background: @border-lighter !important;
	&:before {
		content: '\f142' !important; 
		color: @gray-light !important; 
		font-family: 'FontAwesome' !important;
		top: 7px !important;
		font-size: 14px !important;
	}
}

.dd3-content {
	padding-left: 32px !important;
}

.dd-item {
	font-size: @font-size-base !important;

	> button {
		width: 34px !important;
		height: 22px !important;
		margin: 6px 0 !important;



		&:before {
			-webkit-font-smoothing: antialiased !important;
			font-style: normal !important;
			font-weight: normal !important;
			font-size: @font-size-base !important;
			color: @gray;
		}
		&[data-action='collapse']:before {content: '\f147' !important}
		&[data-action='expand']:before {content: '\f196' !important}
	}
}

.dd3-item {
	font-size: @font-size-base !important;

	> button {
		margin-left: 22px !important;
	}
}

.dd-list .dd-list {
	padding-left: 34px !important;
}

.dd-placeholder,
.dd-empty { 
    margin: 3px 0 !important; 
    padding: 0 !important; 
    min-height: 34px !important; 
    background: @border-lighter !important; 
    border: 2px dashed @gray-light !important;
    border-radius: @border-radius-base !important;
}
.dd-empty { border: 1px dashed @border-lighter !important; min-height: 100px !important; background-color: @tone-brighter;
}


/* Datepicker */
.datepicker th.dow, .datetimepicker th.dow {
	//border-top: 1px solid #ddd !important;
	font-weight: normal !important;
	font-size: 12px !important;
	text-transform: uppercase !important;
}

/* Datetimepicker */
.datetimepicker table thead tr th {
	padding: 5px 0;
}



// jqvmap

.jqvmap-zoomin, .jqvmap-zoomout {
	border-width: 1px;
	border-style: solid;
	border-color: #bdc3c7 #bdc3c7 #b2b9be #bdc3c7;
	background: @brand-default !important;
	color: #666 !important;
	box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.04);

	&:hover {
		background-color: #dde4e6 !important;
		border-color: #b5bbc0 #b5bbc0 #aab1b7 #b5bbc0 !important;

	}

	&:focus {
		color: #666666 !important;
		background-color: #d5dde0 !important;
		border-color: #b5bbc0 !important;
	}


	&:active {
		box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) !important;
	}
}

.jqvmap-zoomin
{
	border-radius: 3px 3px 0 0;
}
.jqvmap-zoomout
{
	top: 17px !important;
	border-radius: 0 0 3px 3px;
}




// ck editor

.cke_float .cke_top {
	border: 1px solid @border-darker !important;
}

.cke_top {
	border-radius: @border-radius-base @border-radius-base 0 0 !important;
	border-bottom: 1px solid @border-darker !important;
	//background: @border-lighter !important;
}

.cke_bottom {
	border-top: 1px solid @border-darker !important;
	border-radius: 0 0 @border-radius-base @border-radius-base !important;
	//background: @border-lighter !important;
}

.cke-focus, .cke-focus .cke-top {
	border-radius: @border-radius-base !important;
	background: @gray-lighter !important;
}

.cke_chrome {
	border: 1px solid @border-darker !important;
	border-radius: @border-radius-base !important;
}

.cke_inner {
	border-radius: @border-radius-base !important;
}

.cke_combo_button {
	border: 1px solid @border-lighter !important;
	border-radius: @border-radius-base !important;
}

.cke_top a:hover {
	border-radius: @border-radius-base !important;
	background: @border-lighter !important;
	border: 1px solid @border-lighter !important;
}





//summernote

.note-editor {
	border: 1px solid @border-darker !important;
	border-radius: @border-radius-base;
}

.note-editor .navbar .btn-navbar {
  background-color: @border-lighter !important;
}

.note-editor .well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid @border-darker !important;
  }

.note-editor .note-toolbar {
	border-radius: @border-radius-small @border-radius-small 0 0;
	background-color: @border-darker !important;
	border-bottom: 1px solid @border-darker !important;
}

.note-popover .popover .popover-content, .note-toolbar {
	//padding: 5px 0 10px 10px !important;
}

.note-editor .note-statusbar .note-resizebar {
	border-top: 1px solid @border-darker !important;
	border-radius: 0 0 @border-radius-base @border-radius-base;
}

.note-editor .note-statusbar {
	background-color: @border-darker !important;
	border-radius: 0 0 @border-radius-small @border-radius-small;
}


//Datatable
.table-fixed-header th {background: #fff !important;}

table.dataTable + .row {
	margin-top: 20px;
}

table.dataTable#editable tr > td > textarea {
	border: 0 !important;
	background: transparent !important;
	box-shadow: none !important;
}

div.DTTT .btn.btn-default {
	color: inherit;
	font-size: inherit;
}

div.dataTables_info {
	padding: 8px 0 !important;
}

//Bootstrap Touchspin

.bootstrap-touchspin {
	.input-group-addon.bootstrap-touchspin-prefix + .form-control {
		.border-left-radius(@border-radius-base);
	}

	.input-group-btn + .input-group-addon.bootstrap-touchspin-prefix + .form-control {
		.border-left-radius(0);
	}

	.input-group-btn-vertical {
		.btn-lg {
			padding-top: 11px !important;
			padding-bottom: 10px !important;
			&.bootstrap-touchspin-down {
				margin-top: -1px !important;
			}
			i {
				top: 5px;
			}
		}
	}
	.bootstrap-touchspin-up {border-top-right-radius: @border-radius-base !important;}
	.bootstrap-touchspin-down {border-bottom-right-radius: @border-radius-base !important;}
}



.table.dataTable thead th.sorting:after,
table.dataTable thead th.sorting_asc:after,
table.dataTable thead th.sorting_desc:after {
	font-weight: 400;
	font-size: 0.8em;
	padding: 0.12em 0;
}


// Mapael

.mapTooltip {
	.opacity(0.9);

	position : fixed;
	padding: 6px 10px;
	background-color : @gray-darker;
	border-radius: @border-radius-small;
	z-index: 1000;
	max-width: 200px;
	display:none;
	font-size: @font-size-small;
	color: #fff;
}


.map {
	position:relative;
	.zoomIn, .zoomOut {
		.btn();
		.btn-default();
		.btn-xs();

		.user-select(none);
		position:absolute;
		top : 0px;
		left : 10px;
	}

	.zoomOut {
		top : 25px;
	}
}


.legendColorBox {
	> div {border-radius: 50% !important; border: none !important; > div {border-radius: 50% !important}}
}



// data table 
table.dataTable {
	margin-top: 0 !important;
	margin-bottom: 0 !important
}



//grid form

.grid-form fieldset legend {
	border: none  !important;
	border-bottom: 2px solid @border-darker !important;
	color: @gray-dark !important;
	font-size: @font-size-h3 !important;
	font-weight: 300 !important;
	padding: 5px 0px !important;
}

.grid-form [data-row-span] {
	border-bottom: 1px solid @border-lighter !important;
}

.grid-form [data-row-span] [data-field-span] {
	border-right: 1px solid @border-lighter !important;
	&:last-child {border-right: none !important;}
}



// js tree 

.sidebar .jstree-default .jstree-clicked {
	background: #fff !important;
	border-radius: @border-radius-small !important;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2) !important;
	&:hover {
		background: #fff !important;
		border-radius: @border-radius-small !important;
		box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2) !important;

	}
}

.sidebar .jstree-default .jstree-hovered {
	background: rgba(0, 0, 0, 0.1) !important;
	border-radius: @border-radius-small !important;
	box-shadow: none !important;
}

.jstree-default .jstree-clicked {
	background: @border-darker !important;
	border-radius: @border-radius-small !important;
	box-shadow: none !important;
}

.jstree-default .jstree-hovered {
	background: @border-lighter !important;
	border-radius: @border-radius-small !important;
	box-shadow: none !important;
}


.jstree-default .jstree-wholerow-clicked {
	background: @border-darker !important;
}

.jstree-default .jstree-wholerow-hovered {
	background: @border-lighter !important;
}



// dials 

input.dial {
	font-weight: 300 !important;
}



// dropzone 


.dropzone {
	border: 1px solid rgba(0,0,0,0.03) !important;
	min-height: 360px;
	background: rgba(0,0,0,0.03) !important;
	padding: 20px !important;
	border: 3px dashed @border-lighter !important;
	border-radius: @border-radius-base;
}

// jquery notific8

.jquery-notific8-container {
	z-index: @zindex-modal-background + 1 !important;
}



// jquery.gantt.

.fn-gantt {
	.fn-content * {.box-sizing(~"content-box") !important;}
	.fn-content .bottom {&, * {.box-sizing(~"border-box") !important;}}

	.bar {
		&.ganttRed {background-color: @brand-danger !important;}
		&.ganttGreen {background-color: @brand-green !important;}
		&.ganttBlue {background-color: @brand-info !important;}
		&.ganttOrange {background-color: @brand-orange !important;}

		&.ganttRed, &.ganttGreen, &.ganttBlue, &.ganttOrange {
			.fn-label {
				text-shadow: none !important; 
				font-weight: normal !important;
				color: rgba(255, 255, 255, 0.9) !important;
			}
		}
	}
}