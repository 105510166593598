// Header Nav
// -------------------------

//Topnav
#topnav {
	z-index: @zindex-navbar + 1;
}


//Main Nav
#headernav {
	.clearfix();
	z-index: @zindex-navbar;
	box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
	

	nav ul.navbar-nav > li {
		> a {
			text-transform: uppercase;
			letter-spacing: 0.1em;
			padding: 20px;
			.subline {
				.hidden-xs();
				text-transform: none;
				letter-spacing: normal;
				display: block; 
				font-size: 12px;
			}
		}

        > ul.dropdown-menu {
            margin-top: -2px !important;
        }
    }

	ul.dropdown-menu {
        border-radius: 0 !important;

		border: 0;
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.12), 0px 1px 0px 0px rgba(0, 0, 0, 0.06), 1px 0px 0px 0px rgba(0, 0, 0, 0.06), -1px 0px 0px 0px rgba(0, 0, 0, 0.06);
		background: #fff !important;
        padding: 0;
        > li {
            border-bottom: 1px solid @border-lighter;
            a {
            	padding: 9px 20px;
            }
            &:last-child {border-bottom: 0 !important;}
        }
	}

	.mega-menu {
		li {padding: 20px;}
		.yamm-col {
			.category {
				font-size: @font-size-base;
				text-transform: uppercase;
				letter-spacing: 0.1em;
				font-weight: 400;
				i {margin-right: 8px;}
			}
			ul {
				margin: 0 -20px;
				li {
					padding: 0;
					a {
						display: block;
						padding: 8px 20px;
						&:hover {
							text-decoration: none;
						}
					}
					&:last-child {border-bottom: 0 !important}
				}
			}
		}
		.carousel {
			.carousel-inner {
				.item {padding: 0;}
			}
			ol.carousel-indicators {
				bottom: 8px;
				li {
					&.active {
						height: 10px;
						width: 10px;
						margin: 1px;
					}
					height: 10px;
					width: 10px;
					border: 1px solid @border-darker !important;
					padding: 0;
				}
			}
			a.carousel-control {
			}
		}
	}

	nav.navbar-collapse {
		border-top: 0;
		padding-left: 20px;
		padding-right: 20px;

		&.in {
			max-height: 200px;
		}
	}
	.navbar-toggle {
		background: none;
		&:hover, &:focus {background: none}
	}
	
}

.navbar-nav .collapsible .mega-menu  ul {
	display: block !important;
	margin-left: -20px !important;
}



@media (max-width: @grid-float-breakpoint-max) {
	#headernav {
		.subline {display: none;}
		
		nav.navbar-collapse {
			box-shadow: none;
			border: 0;
			border-top: 1px solid transparent;
			ul.navbar-nav {
				margin-top: 0;
				margin-bottom: 0;
				li a {padding: 10px 20px;}
			}
		}
		.navbar-nav {
			margin-left: -20px;
			margin-right: -20px;
		}
	}
}