//navs.less
.nav-tabs .dropdown-menu {margin-top:0px;}
.nav-tabs > li > a {margin-right: 0px;}
.nav-tabs > li > a:hover {border-color: @gray-lighter;}

.nav .open > a {
    &, &:hover, &:focus {border-color:@gray-lighter;}
}

//button-groups.less

.btn-toolbar {
	margin-left: 0;
}

.btn.dropdown-toggle {min-width: 20px;}

.btn-group, .btn-group-vertical {
	padding: 0;
	> .btn.fc-state-active {
		z-index: 2;
	}
	.btn-group + .btn-group {
    	margin-left: 10px;
	}
}
 .btn-toolbar {
 	.btn-group {float: none;}
 	> .btn + .btn,
 	> .btn-group + .btn,
 	> .btn + .btn-group,
 	> .btn-group + .btn-group {margin-left: 0px;}
 }
.btn-group {
	> .btn + .dropdown-toggle {
	  padding-left: 5px;
	  padding-right: 5px;
	}
	> .btn-lg + .dropdown-toggle {
	  padding-left: 15px;
	  padding-right: 15px;
	}
	&.open .dropdown-toggle {
		.box-shadow(inset 0 2px 2px rgba(0,0,0,.1));
	}
}

//breadcrumbs.less
.breadcrumb {
	border: 1px solid @border-lighter !important;
	margin-bottom: 0;
	> li {
		color: @breadcrumb-color;
		font-size: @font-size-mini !important;
	}
}

//pagination.less
.pagination {
	margin: 10px 0;
	> li {
		> a, span {
			&:hover {
				background: @border-lighter;
			}
		}
	}
}

//pager.less
.pager li {
	> a, span {
		padding: 6px 15px;
	}
}

//navbar.less
.navbar {
	border: none !important;
}


// navbar toggle 

.navbar-toggle {
  margin-right: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding: 15px 20px !important;
  border-radius: 0 !important;
  border: 0 !important;
  }

// topnav collapse 

body.topnav-collapsed header.navbar .navbar-collapse {
	box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.02) !important;
}

body.topnav-collapsed header.navbar.navbar-inverse .navbar-collapse .open .dropdown-menu {
	//background-color: @dropdown-inverse-bg;
	li a {padding: 5px 20px;}
	.yamm-content li a {padding: 0 20px;}
}

body.topnav-collapsed header.navbar.navbar-default .navbar-collapse .open .dropdown-menu {
	background-color: @navbar-default-link-active-bg;
	li a {padding: 5px 20px;}
	.yamm-content li a {padding: 0 20px;}
}


@media (max-width: @screen-xs-max) {
	.navbar .yamm.navbar-collapse .yamm-content {
		margin: 0 10px;
		ul {margin-bottom: 10px;}
		.yamm-col {
			margin-bottom: 10px;
			border-right: 0 !important;
			&:last-child {margin-bottom: 0;}
		}
	}
}



// list group 

.list-group.nav-tabs {
	border-bottom: 0;
}
