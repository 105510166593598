@base-margin:  5px;
@base-padding: @base-margin;


/* Margin */
.m0, .m-n  	  {margin: 0px   		  !important;}
.m5, .m-xs 	  {margin: @base-margin    !important; &.n{margin: (-@base-margin ) !important} }
.m10,.m-sm 	  {margin: @base-margin*2  !important; &.n{margin: (-@base-margin*2 ) !important} }
.m15,.m    	  {margin: @base-margin*3  !important; &.n{margin: (-@base-margin*3 ) !important} }
.m20,.m-md 	  {margin: @base-margin*4  !important; &.n{margin: (-@base-margin*4 ) !important} }
.m30,.m-lg 	  {margin: @base-margin*6  !important; &.n{margin: (-@base-margin*6 ) !important} }
.m40,.m-xl 	  {margin: @base-margin*8  !important; &.n{margin: (-@base-margin*8 ) !important} }
.m50,.m-xxl	  {margin: @base-margin*10 !important; &.n{margin: (-@base-margin*10) !important} }

.mb0 ,.mb-n   {margin-bottom: 0px   		  !important;}
.mb5 ,.mb-xs  {margin-bottom: @base-margin    !important; &.n{margin-bottom: (-@base-margin   ) !important} }
.mb10,.mb-sm  {margin-bottom: @base-margin*2  !important; &.n{margin-bottom: (-@base-margin*2 ) !important} }
.mb15,.mb     {margin-bottom: @base-margin*3  !important; &.n{margin-bottom: (-@base-margin*3 ) !important} }
.mb20,.mb-md  {margin-bottom: @base-margin*4  !important; &.n{margin-bottom: (-@base-margin*4 ) !important} }
.mb30,.mb-lg  {margin-bottom: @base-margin*6  !important; &.n{margin-bottom: (-@base-margin*6 ) !important} }
.mb40,.mb-xl  {margin-bottom: @base-margin*8  !important; &.n{margin-bottom: (-@base-margin*8 ) !important} }
.mb50,.mb-xxl {margin-bottom: @base-margin*10 !important; &.n{margin-bottom: (-@base-margin*10) !important} }

.ml0 ,.ml-n   {margin-left:  0px 			 !important;}
.ml5 ,.ml-xs  {margin-left: @base-margin    !important; &.n{margin-left: (-@base-margin   ) !important} }
.ml10,.ml-sm  {margin-left: @base-margin*2  !important; &.n{margin-left: (-@base-margin*2 ) !important} }
.ml15,.ml     {margin-left: @base-margin*3  !important; &.n{margin-left: (-@base-margin*3 ) !important} }
.ml20,.ml-md  {margin-left: @base-margin*4  !important; &.n{margin-left: (-@base-margin*4 ) !important} }
.ml30,.ml-lg  {margin-left: @base-margin*6  !important; &.n{margin-left: (-@base-margin*6 ) !important} }
.ml40,.ml-xl  {margin-left: @base-margin*8  !important; &.n{margin-left: (-@base-margin*8 ) !important} }
.ml50,.ml-xxl {margin-left: @base-margin*10 !important; &.n{margin-left: (-@base-margin*10) !important} }

.mr0 ,.mr-n   {margin-right: 0px 			 !important;}
.mr5 ,.mr-xs  {margin-right: @base-margin    !important; &.n{margin-right: (-@base-margin   ) !important} }
.mr10,.mr-sm  {margin-right: @base-margin*2  !important; &.n{margin-right: (-@base-margin*2 ) !important} }
.mr15,.mr     {margin-right: @base-margin*3  !important; &.n{margin-right: (-@base-margin*3 ) !important} }
.mr20,.mr-md  {margin-right: @base-margin*4  !important; &.n{margin-right: (-@base-margin*4 ) !important} }
.mr30,.mr-lg  {margin-right: @base-margin*6  !important; &.n{margin-right: (-@base-margin*6 ) !important} }
.mr40,.mr-xl  {margin-right: @base-margin*8  !important; &.n{margin-right: (-@base-margin*8 ) !important} }
.mr50,.mr-xxl {margin-right: @base-margin*10 !important; &.n{margin-right: (-@base-margin*10) !important} }

.mt0 ,.mt-n   {margin-top: 0px				!important;}
.mt5 ,.mt-xs  {margin-top: @base-margin    !important; &.n{margin-top: (-@base-margin   ) !important} }
.mt10,.mt-sm  {margin-top: @base-margin*2  !important; &.n{margin-top: (-@base-margin*2 ) !important} }
.mt15,.mt     {margin-top: @base-margin*3  !important; &.n{margin-top: (-@base-margin*3 ) !important} }
.mt20,.mt-md  {margin-top: @base-margin*4  !important; &.n{margin-top: (-@base-margin*4 ) !important} }
.mt30,.mt-lg  {margin-top: @base-margin*6  !important; &.n{margin-top: (-@base-margin*6 ) !important} }
.mt40,.mt-xl  {margin-top: @base-margin*8  !important; &.n{margin-top: (-@base-margin*8 ) !important} }
.mt50,.mt-xxl {margin-top: @base-margin*10 !important; &.n{margin-top: (-@base-margin*10) !important} }


/* Paddiing */

.p0, .p-n  	  {padding: 0px   		  !important;}
.p5, .p-xs 	  {padding: @base-padding    !important; &.n{padding: (-@base-padding ) !important} }
.p10,.p-sm 	  {padding: @base-padding*2  !important; &.n{padding: (-@base-padding*2 ) !important} }
.p15,.p    	  {padding: @base-padding*3  !important; &.n{padding: (-@base-padding*3 ) !important} }
.p20,.p-md 	  {padding: @base-padding*4  !important; &.n{padding: (-@base-padding*4 ) !important} }
.p30,.p-lg 	  {padding: @base-padding*6  !important; &.n{padding: (-@base-padding*6 ) !important} }
.p40,.p-xl 	  {padding: @base-padding*8  !important; &.n{padding: (-@base-padding*8 ) !important} }
.p50,.p-xxl	  {padding: @base-padding*10 !important; &.n{padding: (-@base-padding*10) !important} }

.pb0 ,.pb-n   {padding-bottom: 0px   		  !important;}
.pb5 ,.pb-xs  {padding-bottom: @base-padding    !important; &.n{padding-bottom: (-@base-padding   ) !important} }
.pb10,.pb-sm  {padding-bottom: @base-padding*2  !important; &.n{padding-bottom: (-@base-padding*2 ) !important} }
.pb15,.pb     {padding-bottom: @base-padding*3  !important; &.n{padding-bottom: (-@base-padding*3 ) !important} }
.pb20,.pb-md  {padding-bottom: @base-padding*4  !important; &.n{padding-bottom: (-@base-padding*4 ) !important} }
.pb30,.pb-lg  {padding-bottom: @base-padding*6  !important; &.n{padding-bottom: (-@base-padding*6 ) !important} }
.pb40,.pb-xl  {padding-bottom: @base-padding*8  !important; &.n{padding-bottom: (-@base-padding*8 ) !important} }
.pb50,.pb-xxl {padding-bottom: @base-padding*10 !important; &.n{padding-bottom: (-@base-padding*10) !important} }

.pl0 ,.pl-n   {padding-left:  0px 			 !important;}
.pl5 ,.pl-xs  {padding-left: @base-padding    !important; &.n{padding-left: (-@base-padding   ) !important} }
.pl10,.pl-sm  {padding-left: @base-padding*2  !important; &.n{padding-left: (-@base-padding*2 ) !important} }
.pl15,.pl     {padding-left: @base-padding*3  !important; &.n{padding-left: (-@base-padding*3 ) !important} }
.pl20,.pl-md  {padding-left: @base-padding*4  !important; &.n{padding-left: (-@base-padding*4 ) !important} }
.pl30,.pl-lg  {padding-left: @base-padding*6  !important; &.n{padding-left: (-@base-padding*6 ) !important} }
.pl40,.pl-xl  {padding-left: @base-padding*8  !important; &.n{padding-left: (-@base-padding*8 ) !important} }
.pl50,.pl-xxl {padding-left: @base-padding*10 !important; &.n{padding-left: (-@base-padding*10) !important} }

.pr0 ,.pr-n   {padding-right: 0px 			 !important;}
.pr5 ,.pr-xs  {padding-right: @base-padding    !important; &.n{padding-right: (-@base-padding   ) !important} }
.pr10,.pr-sm  {padding-right: @base-padding*2  !important; &.n{padding-right: (-@base-padding*2 ) !important} }
.pr15,.pr     {padding-right: @base-padding*3  !important; &.n{padding-right: (-@base-padding*3 ) !important} }
.pr20,.pr-md  {padding-right: @base-padding*4  !important; &.n{padding-right: (-@base-padding*4 ) !important} }
.pr30,.pr-lg  {padding-right: @base-padding*6  !important; &.n{padding-right: (-@base-padding*6 ) !important} }
.pr40,.pr-xl  {padding-right: @base-padding*8  !important; &.n{padding-right: (-@base-padding*8 ) !important} }
.pr50,.pr-xxl {padding-right: @base-padding*10 !important; &.n{padding-right: (-@base-padding*10) !important} }

.pt0 ,.pt-n   {padding-top: 0px				!important;}
.pt5 ,.pt-xs  {padding-top: @base-padding    !important; &.n{padding-top: (-@base-padding   ) !important} }
.pt10,.pt-sm  {padding-top: @base-padding*2  !important; &.n{padding-top: (-@base-padding*2 ) !important} }
.pt15,.pt     {padding-top: @base-padding*3  !important; &.n{padding-top: (-@base-padding*3 ) !important} }
.pt20,.pt-md  {padding-top: @base-padding*4  !important; &.n{padding-top: (-@base-padding*4 ) !important} }
.pt30,.pt-lg  {padding-top: @base-padding*6  !important; &.n{padding-top: (-@base-padding*6 ) !important} }
.pt40,.pt-xl  {padding-top: @base-padding*8  !important; &.n{padding-top: (-@base-padding*8 ) !important} }
.pt50,.pt-xxl {padding-top: @base-padding*10 !important; &.n{padding-top: (-@base-padding*10) !important} }


// Responsive Embed
.embed-container {
	position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; height: auto; 
	iframe, object, embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
} 


//Veritcally Align
.vertical-align {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
}

//Vertical Align: Table

.vat {vertical-align: top    !important;}
.vam {vertical-align: middle !important;}
.vab {vertical-align: bottom !important;}

table {
	&.table-vam {
		> thead > tr > th,
		> tbody > tr > td,
		> tfoot > tr > th {
			&:extend(.vam);
		}
	}
	&.table-vat {
		> thead > tr > th,
		> tbody > tr > td,
		> tfoot > tr > th {
			&:extend(.vat);
		}
	}
	&.table-vab {
		> thead > tr > th,
		> tbody > tr > td,
		> tfoot > tr > th {
			&:extend(.vab);
		}
	}
}


// Tabular
.tabular {
	display: table; 
	width: 100%;
	.tabular-row {
		display: table-row;
		.tabular-cell {display: table-cell;vertical-align: middle;}
	}
    .clearfix {border-collapse: collapse;}
}


//Avatar
.avatar {width: 40px;height: 40px; border-radius: 50%}


//Custom Scrollbar Styilings
.scrollbar-custom {
	overflow-y: scroll;

	&::-webkit-scrollbar{width: 6px;}
	&::-webkit-scrollbar-thumb{background-color:rgb(196, 196, 196); border-radius: 0;}
	&::-webkit-scrollbar-thumb:hover{background-color:rgb(196, 196, 196);}
	&::-webkit-scrollbar-track{background-color:rgb(237, 237, 237);}
}