//
// Timeline
// --------------------------------------------------

.timeline-month {
	width: 90px;
	text-align: center;
	position: relative;
	left: 6%;
	margin-left: -42px;
	span {display: block;}
}

.timeline {
	.list-unstyled;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		top: 10px;
		bottom: 0;
		width: 2px;
		background: @border-lighter;
		left: 6%;
		margin-left: 2px;
	}
	> li {
		position: relative;
		.timeline-header {
			.clearfix();
			padding: 0 0 10px;
			color: @gray-light;

			span.date {float: left; text-transform: uppercase;}
			span.author {float: right; font-size:12px; display: none;}
		}

		.timeline-icon {
			width: 50px;
			height: 50px;
			font-size: 1.4em;
			line-height: 50px;
			-webkit-font-smoothing: antialiased;
			position: absolute;
			color: @gray-darker;
			background: @border-lighter;
			border-radius: 50%;
			text-align: center;
			left: 6%;
			top: 0;
			margin: 0 0 0 -22px;
			padding: 0;
		}
		.timeline-body {
			margin: 0 0 20px 14%;
			padding: 20px;
			position: relative;
			border-width: 2px;
			border-style: solid;
			border-radius: @border-radius-large;
			background-color: #fff;
			&:after {
				right: 100%;
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: transparent;
				border-width: 10px;
				top: 5%;
			}
			img {margin: 5px 20px 0 0;}
		}
		.timeline-footer {
			.clearfix();
			color: #fff;
		}
	}
}

@media (max-width: @screen-sm-max) {

	.timeline-month {
		width: 100%;
		text-align: center;
		margin-left: 0;
		left: 0;
		span {display: inline !important;}
	}

	.timeline:before {width: 0;left: 0;}
	.timeline > li {
		margin-top: 20px;
		&:first-child {margin-top: 0;}

		.timeline-body {
			margin: 0 !important;
			&:after {border-width: 0;}
		}

		.timeline-icon {
			width: 30px;
			height: 30px;
			line-height: 30px;
			font-size: 1em;
			margin: 20px 50px 0 0;
			position: relative;
			float: right;
		}
	}
}


.timeline-primary 	{.timeline-states(@brand-primary);}
.timeline-warning      {.timeline-states(@brand-warning);}
.timeline-danger       {.timeline-states(@brand-danger);}
.timeline-success      {.timeline-states(@brand-success);}
.timeline-info         {.timeline-states(@brand-info);}
.timeline-inverse      {.timeline-states(@brand-inverse);}

// custom colors
.timeline-brown        {.timeline-states(@brand-brown);}  
.timeline-indigo       {.timeline-states(@brand-indigo);}
.timeline-orange       {.timeline-states(@brand-orange);}
.timeline-sky          {.timeline-states(@brand-sky);}
.timeline-midnightblue {.timeline-states(@brand-midnightblue);}
.timeline-magenta      {.timeline-states(@brand-magenta);}  
.timeline-green        {.timeline-states(@brand-green);} 
.timeline-purple       {.timeline-states(@brand-purple);} 


.timeline-states(@timeline-color) {
	.timeline-body 			{border-color: @timeline-color}
	.timeline-body:after 	{border-right-color: @timeline-color !important;}
	.timeline-time 			{color: @timeline-color}
}